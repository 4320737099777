var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm._m(0)
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { attrs: { id: "terms_conditions_page" } }, [
      _c("div", { staticClass: "container" }, [
        _c("h1", [_vm._v("Terms & Conditions")]),
        _c("h2", [_vm._v("A. Introduction")]),
        _c("p", [
          _vm._v(
            "Our carpooling and smart mobility app enables you to easily and in real time connect with fellow workers, students and other people who are part of your college, company or community to provide a trusted network enabling you and others you trust to travel together, reducing congestion on the roads, saving you money, time and providing benefits to the environment. Users of the Sharo App, website and facilities available through it (also referred to as Members) can search for travel companions (whether in your own car or theirs) in a similar location and arrange shared travel with other Members."
          )
        ]),
        _c("p", [
          _vm._v(
            "The Sharo App is operated by Sharo Technologies Limited, trading as Sharo Mobility, a private company registered in Ireland (number 623640) with an office at 4/5 Burton Hall Park , Sandyford Industrial Estate, Dublin D18 A094."
          )
        ]),
        _c("p", [_vm._v("In these Terms:")]),
        _c("ul", [
          _c("li", [
            _vm._v(
              "(1) References to ‘you’, ‘your’ and ‘yours’ are references to the person accessing the Sharo App, website and/or using any of the Facilities together with any party represented by such person or end user."
            )
          ]),
          _c("li", [
            _vm._v(
              "(2) References to ‘Sharo’, ‘we’, ‘us’ and ‘our’ refer to Sharo Technologies Limited "
            )
          ])
        ]),
        _c("p", [
          _vm._v(
            "If you wish to contact us please refer to the Contact section which can be found on our website sharo.io."
          )
        ]),
        _c("h2", [_vm._v("B. Important notices")]),
        _c("p", [
          _vm._v(
            "This notice applies to the entire contents of the Sharo App, website (“Website”) and to all communications between us and you."
          )
        ]),
        _c("p", [
          _vm._v(
            "Please read these Terms carefully before using the App or becoming (or applying to become) a Member, user or administrator. By becoming a Member, user or administrator of the App, you agree to be bound by and comply with the following Terms. If you do not accept these Terms you should not use nor register with the App. By registering with the App and/or using the Website and/or accessing any of the Facilities you are deemed to accept these Terms."
          )
        ]),
        _c("p", [
          _vm._v(
            "By ticking a box next to “I have read and accept the Terms and Conditions and the Privacy Policy” and/or clicking the ‘Start’ button, you accept and agree to be bound by these Terms and represent and warrant that you have sufficient right, authority and capacity to enter into and abide by all of the terms and conditions set out herein."
          )
        ]),
        _c("p", [
          _vm._v(
            "We retain the right to modify the Terms at any time. Any Member may refuse the amendments proposed, however, continued use of the Services provided by the App constitutes acceptance of the revised Terms and Conditions of Use. Members whom do not accept the amended conditions may simply close their accounts in accordance with our termination guidelines. It is the responsibility of the Member to check these Terms from time to time."
          )
        ]),
        _c("p", [
          _vm._v(
            "Without prejudice to condition 9 below of these Terms please note that:"
          )
        ]),
        _c("ul", [
          _c("li", [
            _vm._v(
              "The App provides a method by which individuals may initiate contact with each other. We do not provide a matching service between members or purport to offer advice as to transport and/or suitable travel companions which remain entirely your responsibility;"
            )
          ]),
          _c("li", [
            _vm._v(
              "Users of the App are advised that no checks whatsoever are made against anyone joining the App and/or using it to offer or accept offers. Individuals should, therefore, exercise all due caution and satisfy themselves that the person or persons that they are travelling with are suitable and Sharo and Client Organisations are not responsible in any way;"
            )
          ]),
          _c("li", [
            _vm._v(
              "Sharo does NOT provide transportation services, and is not a transportation carrier. It is up to the transportation provider, driver or vehicle operator to offer transportation services which may be scheduled using the App and/or Facilities. Sharo offers information and a method to schedule and share travel, but does not in any way provide transportation services or act in any way as a transportation carrier, and neither we nor our Client Organisations have any responsibility or liability for any transportation services provided to or by you."
            )
          ]),
          _c("li", [_vm._v("We strongly advise you to read our Safety Tips. ")])
        ]),
        _c("h2", [_vm._v("C. Safety statement")]),
        _c("p", [
          _vm._v(
            "We take the privacy and safety of Members very seriously, and are committed to safeguarding their privacy. To that end we ask that Members take note of and follow our Privacy Policy, Rules of Behaviour, Safety Tips and the provisions of the Terms, in particular (but without limitation) condition 9 (Limitation of Liability). We rely upon Members behaving well and properly towards each other and with mutual respect and consideration and take responsibility for their own actions and decisions."
          )
        ]),
        _c("h2", [_vm._v("1. Definitions")]),
        _c("p", [
          _vm._v(
            "1.1.\tThe definitions and rules of interpretation in this condition 1.1 apply to these Terms."
          )
        ]),
        _c("ul", [
          _c("li", [
            _c("b", [_vm._v('"Administrator”')]),
            _vm._v(
              " means a person appointed by a Client Organisation to administer their Group.The Administrator may also be a user within the Group."
            )
          ]),
          _c("li", [
            _c("b", [_vm._v('"Client Organisation”')]),
            _vm._v(
              " means an entity that has purchased a subscription to use the Sharo App and/or Facilities on a trial or continuous basis."
            )
          ]),
          _c("li", [
            _c("b", [_vm._v('"Facilities”')]),
            _vm._v(
              " means any or all of the facilities available to Members on or through the App."
            )
          ]),
          _c("li", [
            _c("b", [_vm._v('"Group”')]),
            _vm._v(
              " means a group of Users administered by one or more Administrators; the App is used by a number of Groups."
            )
          ]),
          _c("li", [
            _c("b", [_vm._v('"Member”')]),
            _vm._v(
              " means any person registered on the App or who is an User or Administrator and “Membership” shall be construed accordingly."
            )
          ]),
          _c("li", [
            _c("b", [_vm._v('"Terms”')]),
            _vm._v(
              " means these terms and conditions of use (including the introduction, the important notices above, the safety statement, all conditions and the Privacy Policy) and any amendments to or revisions of any of the same as may be published from time to time by Sharo on the App or website from time to time."
            )
          ]),
          _c("li", [
            _c("b", [_vm._v('"App”')]),
            _vm._v(
              " means the Sharo app, website and Facilities the purpose of which is to facilitate on-line contact between individuals to schedule travel sharing and otherwise."
            )
          ]),
          _c("li", [
            _c("b", [_vm._v('"User”')]),
            _vm._v(" means a person who has registered to use the App.")
          ]),
          _c("li", [
            _c("b", [_vm._v('"Service Provider”')]),
            _vm._v(
              " means a third-party entity or person providing transportation (e.g shuttle bus, bike/vehicle sharing or rental, public transport, etc.), payment  and/or any other service that can be initially accessed, booked or otherwise linked using the App "
            )
          ])
        ]),
        _c("p", [
          _vm._v(
            "1.2.\tThe expression “including” as used is to be construed as being immediately followed by the expression “without limitation”. Consequently, any such phrase shall be construed as illustrative and shall not limit the sense of the words preceding those terms."
          )
        ]),
        _c("p", [
          _vm._v(
            "1.3.\tWords appearing in the singular shall include the plural and vice versa."
          )
        ]),
        _c("p", [
          _vm._v(
            "1.4.\tHeadings to conditions, paragraphs, policies and/or to any documents published and/or accessible through the App or website do not affect the interpretation of these Terms"
          )
        ]),
        _c("p", [
          _vm._v(
            "1.5.\tReferences to a statute, statutory provision or subordinate legislation made under a statute is to be construed as being to that statute, provision or subordinate legislation as amended or re-enacted from time to time whether before or after the date appearing at the top of these Terms as the last revision date. Where we refer to a statute, that reference includes any subordinate legislation made under that statute whether before or after the date of the latest revision of these Terms."
          )
        ]),
        _c("p", [
          _vm._v(
            '1.6.\tA reference to "writing" or "written" includes emails unless otherwise stated.'
          )
        ]),
        _c("h2", [_vm._v("2. Eligibility, registration and your account")]),
        _c("p", [
          _vm._v(
            "2.1.\tIn order to validly register as a Member of Sharo and use the App, you must be at least eighteen years old."
          )
        ]),
        _c("p", [
          _vm._v(
            "2.2.\tTo become a Member of Sharo you must complete the registration process and/or subscribe and agree to these Terms. Registration is necessary for anyone wishing to access and/or use the App and the Facilities. Information provided must be current, complete and accurate in all respects. You undertake to keep all details current. We have the right to refuse to grant you an account at our sole discretion."
          )
        ]),
        _c("p", [
          _vm._v(
            "2.3.\tSubject to our right of refusal referred to in 2.2, upon registering, you will be asked to enter your email address (as your username) and, later in the process, a password. Your password is strictly private and confidential and you are entirely responsible for ensuring that this is not communicated to or shared with any other person. We are entitled (but not obliged) to terminate your account at any time, including if we become aware that you have disclosed your password to any other person. Please note that you are entirely and solely responsible for your posted journeys and all communications using the App. We have no obligation to monitor any journeys or communications."
          )
        ]),
        _c("p", [
          _vm._v(
            "2.4.\tWe are not obliged nor do we have the technical means to check the identities of every person registered to use the Sharo App. The use of your account is expressly limited to you, and you are not entitled to transfer or share your account with any other person (temporarily or permanently). We and our Client Organisations are not liable and you are solely responsible if a Member’s identity is used by another person, and you indemnify us against all losses, damages and claims relating to such use. If you have any reason to believe that another person may be using your identification information or account you must inform us immediately by contacting us. To protect your privacy, we will suspend access or change access to your account as soon as possible following notification by you that your password has been lost, stolen or otherwise compromised."
          )
        ]),
        _c("p", [
          _vm._v(
            "2.5.\tYou accept entire responsibility for any and all statements made and activities that occur through your account."
          )
        ]),
        _c("p", [
          _vm._v(
            "2.6.\tYou are responsible for all equipment (including but not limited to all computer, software, telephone, internet and other telecommunication systems) used to access the App and the Facilities, together with any charges or costs incurred as a result of its use."
          )
        ]),
        _c("p", [
          _vm._v(
            "2.7.\tYou hereby acknowledge that the Facilities and the Website is contingent on certain software applications from third party service providers. You hereby agree to the following third party terms (as updated from time to time) which shall apply to the Facilities and the Website in addition to these Terms:"
          )
        ]),
        _c("p", [
          _vm._v(
            "2.7.1.\tGoogle Maps/Google Earth Additional Terms of Service (and the documents referenced therein): https://www.google.com/help/terms_maps.html."
          )
        ]),
        _c("p", [
          _vm._v(
            "2.7.2.\tGoogle Cloud Platform Additional Terms of Service (and the documents referenced therein): https://cloud.google.com/terms/"
          )
        ]),
        _c("p", [
          _vm._v(
            "2.7.3.\tFirebase Platform Additional Terms of Service (and the documents referenced therein): https://firebase.google.com/terms"
          )
        ]),
        _c("p", [
          _vm._v(
            "2.7.4.\tSendGrid/Twilio Additional Terms of Service (and the documents referenced therein): https://www.twilio.com/legal/tos"
          )
        ]),
        _c("p", [
          _vm._v(
            "2.7.5.\tNexmo Additional Terms of Service (and the documents referenced therein): https://www.nexmo.com/terms-of-use"
          )
        ]),
        _c("h2", [
          _vm._v("3. Journey matching and scope of App and the Facilities")
        ]),
        _c("p", [
          _vm._v(
            "3.1.\tThe App and/or the Facilities enable you to contact other Members with a view to sharing transport so that you can travel together and to search for travel companions. It is not our purpose to broker, arrange or advise you of travel sharing arrangements for you or for other Members with you. The App and the Facilities do not include arranging off-line meetings between Members, supplying access to the internet network, or providing an electronic communication service to the public. As such, we have no legal obligation to identify Members when they connect to the App or to monitor content published by Members. Because we are unable technically to provide such services, we do not undertake contractually to identify Members or to moderate the content published by any of them (which remains their sole responsibility)."
          )
        ]),
        _c("p", [
          _vm._v(
            "3.2.\tNeither we nor our Client Organisations shall be liable for any false or abusive statements made by a Member. We do not make any checks whatsoever against anyone joining Sharo. For the avoidance of doubt, no checks are made by us of Member’s driving licences, authorisations, and vehicles (including whether they are roadworthy and/or whether current NCT certificates and/or insurances are held). You are strongly advised, therefore, to exercise caution and to satisfy yourself that the person or persons with whom you may travel are suitable and where you propose to be a passenger in a car driven by them (or some person authorised by them) as to the validity of the driver’s driving licence, insurances, NCT certificate and other requirements needed for their vehicle and ride sharing (as appropriate). Therefore, it is imperative that you carry out all necessary investigations of your fellow travellers and vehicles when arranging to share travel arrangements and transport. You must also take all relevant precautions when arranging to meet with another Member."
          )
        ]),
        _c("p", [
          _vm._v(
            "3.3.\tNeither we nor our Client Organisations shall be liable for any loss or damage arising from any accidents or incidents, or other consequences arising out of meetings between Members, whether on the App, through any of the Facilities or in person during meetings between Members or non-Members as a consequence of the use of the App or other Facilities. In addition, it is recommended that you tell someone who you trust when you arrange a meeting and that you arrange to meet in a public place."
          )
        ]),
        _c("h2", [_vm._v("4. Rules of behaviour")]),
        _c("p", [
          _vm._v(
            "4.1.\tSharo accommodates a diversity of interests and opinions. However, Members must conduct themselves properly and behave well towards each other and with mutual respect and consideration and take responsibility for their own actions and decisions. Sharo App and Facilities may not be used to post or transmit any unlawful, harmful, threatening, abusive, harassing, defamatory, or otherwise objectionable material of any kind, including, but not limited to, any material which encourages conduct that would harm our business interests, constitute a criminal offence, exploit children and/or vulnerable adults, violate rights of others, or otherwise violate any applicable local, state, national, or international law. We reserve the right to remove any Member or group of Members, which in our sole judgement, breaches this condition and/or breaches the undertakings and/or obligations set out in clauses 4.3, 4.4 and 4.5 below."
          )
        ]),
        _c("p", [
          _vm._v(
            "4.2.\tWithout prejudice to condition 12 (Termination) below, you undertake not to use any of the Facilities (including the App):"
          )
        ]),
        _c("p", [
          _vm._v(
            "4.2.1.\tin any way that breaches any applicable local, national or international law or regulation;"
          )
        ]),
        _c("p", [
          _vm._v(
            "4.2.2.\tin any way that is unlawful or fraudulent, or has any unlawful or fraudulent purpose or effect;"
          )
        ]),
        _c("p", [
          _vm._v(
            "4.2.3.\tfor the purpose of harming or harassing or bullying or causing nuisance or annoyance, or attempting to harm or harass or bully or cause nuisance or annoyance to any person (including minors and/or vulnerable adults) in any way;"
          )
        ]),
        _c("p", [
          _vm._v(
            "4.2.4.\tto send, knowingly receive, upload, download, use or re-use any material which does not comply with condition 4.5 below;"
          )
        ]),
        _c("p", [
          _vm._v(
            "4.2.5.\tto advertise, promote, market, solicit orders for or offer to buy, sell, lease or license products, goods and facilities;"
          )
        ]),
        _c("p", [
          _vm._v(
            "4.2.6.\tto transmit, or procure the sending of, any unsolicited or authorised advertising or promotional material or any other form of similar solicitation;"
          )
        ]),
        _c("p", [
          _vm._v(
            "4.2.7.\tto knowingly or without due care transmit any data, send or upload any material or information that contains viruses, Trojan horses, worms, time-bombs, keystroke loggers, spyware, adware, or any other harmful programs or similar computer code designed to adversely affect the operation of any computer software or hardware;"
          )
        ]),
        _c("p", [
          _vm._v(
            "4.2.8.\tto harvest, aggregate, mine, copy or otherwise collect information about others, including but not limited to names, email addresses, passwords, telephone numbers, usage information without their consent;"
          )
        ]),
        _c("p", [
          _vm._v(
            "4.2.9.\tto attempt to gain unauthorised access to, tamper with, modify, or hack into, or reverse engineer any of the Facilities (including the App);"
          )
        ]),
        _c("p", [_vm._v("4.2.10.\tfor resale to any third party;")]),
        _c("p", [_vm._v("4.2.11.\tother than for your personal use.")]),
        _c("p", [_vm._v("4.3.\tYou also undertake:")]),
        _c("p", [
          _vm._v(
            "4.3.1.\tnot to reproduce, duplicate, copy, reverse-engineer, or sell all or any part of the App and/or the Facilities."
          )
        ]),
        _c("p", [
          _vm._v(
            "4.3.2.\tnot to access without authority, interfere with, damage or disrupt;"
          )
        ]),
        _c("p", [
          _vm._v("4.3.3.\tall or any part of the App and/or the Facilities;")
        ]),
        _c("p", [
          _vm._v(
            "4.3.4.\tall or any equipment or network on which the App and/or any of the Facilities is stored;"
          )
        ]),
        _c("p", [
          _vm._v(
            "4.3.5.\tall or any software used in the provision of the App and/or any of the Facilities; or"
          )
        ]),
        _c("p", [
          _vm._v(
            "4.3.6.\tall or any equipment or network or software owned or used by any third party."
          )
        ]),
        _c("p", [
          _vm._v(
            "4.3.7.\tto use your username and password solely for the purpose of your authentication for access to the App and the Facilities. For the avoidance of doubt, you are prohibited from creating a Membership id/profile using a false identity for the purpose of misleading others (including us) of your identity or to impersonate another person;"
          )
        ]),
        _c("p", [
          _vm._v(
            "4.3.8.\tto keep secure and confidential your account details and password, and any identification or code we provide to you for use in relation to the App and Facilities;"
          )
        ]),
        _c("p", [
          _vm._v(
            "4.3.9.\tto provide us with whatever proof of identity we may reasonably request at any time; and"
          )
        ]),
        _c("p", [
          _vm._v(
            "4.3.10.\tto use the App and the Facilities in accordance with the purpose set out in these Terms."
          )
        ]),
        _c("p", [
          _vm._v(
            "4.4.\tWithout prejudice to condition 12 (Termination) below, you undertake to comply with the following obligations in relation to the content of the material which you may from time to time contribute to or upload onto or obtain from the App or the Facilities:"
          )
        ]),
        _c("p", [
          _vm._v(
            "4.4.1.\tto ensure that all content posted, published, uploaded, disseminated and/or broadcast in any form whatsoever is accurate, genuinely held and complies with applicable and prevailing law from time to time both in Ireland, and in the event that it is not posted, uploaded, disseminated or broadcast in Ireland in the country from which it is so posted uploaded, disseminated or broadcast;"
          )
        ]),
        _c("p", [
          _vm._v(
            "4.4.2.\tnot to post, publish, upload, disseminate or broadcast in any form whatsoever content that contravenes the rights of any other person or that is defamatory, obscene, injurious, offensive, hateful or inflammatory, violent or inciting violence, sexually explicit or promotes sexually explicit material, political or fraudulent, and, in general, any content that is contrary to the purpose of Sharo, prevailing laws or rules and/or prevailing acceptable norms and standards;"
          )
        ]),
        _c("p", [
          _vm._v(
            "4.4.3.\tnot to post, publish, upload, disseminate and/or broadcast in any form whatsoever content that promotes discrimination of any type;"
          )
        ]),
        _c("p", [
          _vm._v(
            "4.4.4.\tnot to post, publish, disseminate, upload, broadcast or reproduce any information protected by copyright, trademark or other intellectual property or proprietary information of others, and/or do anything which may infringe any copyright, database right, trade mark or other intellectual property of any other person, without first obtaining prior written consent of the owner of such proprietary works, data or information;"
          )
        ]),
        _c("p", [
          _vm._v(
            "4.4.5.\tnot to post, publish, disseminate, broadcast and/or knowingly cause to be posted, disseminated, or broadcast in any form whatsoever content or links to third-party websites;"
          )
        ]),
        _c("p", [
          _vm._v(
            "4.4.6.\tnot to post, publish, disseminate, upload or broadcast in any form whatsoever content that is likely to deceive, harass, upset, embarrass, alarm or cause annoyance, inconvenience or needless anxiety to any person;"
          )
        ]),
        _c("p", [
          _vm._v(
            "4.4.7.\tnot to post, publish, disseminate, upload or broadcast in any form whatsoever content to impersonate any person or to misrepresent your identity or affiliation with any person;"
          )
        ]),
        _c("p", [
          _vm._v(
            "4.4.8.\tnot to post, publish, disseminate and/or broadcast in any form whatsoever content which advocates, promotes or assists any unlawful act such as (by way of example only) copyright infringement or computer misuse;"
          )
        ]),
        _c("p", [
          _vm._v(
            "4.4.9.\tnot to post, publish, disseminate or broadcast in any form content which could violate the privacy, proprietary and/or other rights of any person or entity, including any personal information provided by another Member (including their email address, postal address, telephone number) which would enable a Member to contact another Member without using Sharo;"
          )
        ]),
        _c("p", [
          _vm._v(
            "4.4.10.\tnot to post, publish, disseminate and/or broadcast in any form whatsoever content in breach of any legal duty (including any contractual duty or a duty of confidence owed to any other person);"
          )
        ]),
        _c("p", [
          _vm._v(
            "4.4.11.\tnot to express or imply that any statements you make are endorsed by or emanate from us and/or the App without our specific prior written consent."
          )
        ]),
        _c("p", [
          _vm._v(
            "4.5.\tFailure on your part to comply with all or any of the obligations and/or undertakings set out in clauses 4.3, 4.4 and/or this condition 4.5 will constitute a serious breach by you of your obligations to us and/or under these Terms. If you breach one or more of these obligations or undertakings, then without prejudice to condition 12 (Termination) below we shall be entitled to take all or any of the following steps:"
          )
        ]),
        _c("p", [
          _vm._v(
            "4.5.1.\timmediate, temporary or permanent withdrawal of your right to use the App;"
          )
        ]),
        _c("p", [
          _vm._v(
            "4.5.2.\timmediate, temporary or permanent removal of any posting or material uploaded by you to the App;"
          )
        ]),
        _c("p", [
          _vm._v(
            "4.5.3.\tcommence legal proceedings against you including reimbursement of all costs on an indemnity basis (including but not limited to, reasonable administration and legal costs) resulting from your failure to so comply."
          )
        ]),
        _c("p", [
          _vm._v(
            "4.5.4.\tdisclose such information to law enforcement and/or other competent authorities as we may reasonably feel is necessary."
          )
        ]),
        _c("p", [
          _vm._v(
            "4.6.\tThe steps described in condition 4.5 above are not exhaustive, and we may take any other action which we deem to be appropriate acting in our absolute discretion."
          )
        ]),
        _c("h2", [_vm._v("5. Access to the App and the Facilities")]),
        _c("p", [
          _vm._v(
            "5.1.\tAccess to the App and the Facilities is provided on an “as is, as available” basis. We reserve the right at any time and for any or no reason to limit, deny, modify or discontinue all or any part of the App and/or the Facilities with or without prior notice to you. Neither we or our Client Organisations shall be liable to you or to any other person if, for any reason, all or any part of the App and/or the Facilities are unavailable at any time and for any period (including in the event of their total or partial discontinuance by us)."
          )
        ]),
        _c("p", [
          _vm._v(
            "5.2.\tYou are responsible for making all arrangements necessary for you to have access to the App and the Facilities. Without prejudice to condition 2.3, you are responsible for ensuring that all persons who access the App or the Facilities through your internet connection are made aware of these terms, and that they comply with them."
          )
        ]),
        _c("p", [
          _vm._v(
            "5.3.\tWe give no warranty that the App and the Facilities and any graphics or other content you may download from time to time from the App, website or the Facilities will meet your requirements, or that the App or the Facilities will be uninterrupted, timely, secure, or error free; nor do we give any warranty as to the results that may be obtained from the use of the App and/or the Facilities and/or as to the accuracy or reliability of any information obtained through all or any of the App and/or the Facilities or that defects in all or any of the App and/or the Facilities will be correct."
          )
        ]),
        _c("p", [
          _vm._v(
            "5.4.\tWe aim to update the App and/or the Facilities regularly, and may, at any time, change the content or archive or delete any of the journeys on the App or the Facilities. If the need arises, we may suspend access to the App and Facilities and close it or them indefinitely. Any of the material on the App and/or the Facilities may be out of date at any given time, and we shall be under no obligation to update such material."
          )
        ]),
        _c("p", [
          _vm._v(
            "5.5.\tWe are under no obligation to oversee, monitor, review or moderate discussions, chats, postings, transmissions, and groups. We expressly exclude our liability for any loss or damage arising from the use of the App and/or any Facilities by a Member in contravention of condition 4.2, 4.3 and 4.4 of these Terms."
          )
        ]),
        _c("h2", [_vm._v("6. Privacy")]),
        _c("p", [
          _vm._v(
            "6.1.\tOur approach to the capture, storing, sharing and use of information and data (including data supplied by you) is set out in our Privacy Policy which forms part of these Terms. You are strongly advised to read the Privacy Policy carefully."
          )
        ]),
        _c("h2", [_vm._v("7. Intellectual property")]),
        _c("p", [
          _vm._v(
            "7.1.\tWith the exception of any pre-existing intellectual property rights in any trademarks, logos, graphics and text or other intellectual property rights owned by a Client Organisation, which remain the exclusive property of the Client Organisation, all rights title and interests in the trademarks, logos, graphics, animations and text used within the Facilities and those featured on the App or website are Sharo’s intellectual property (“Sharo IPR”) and, as such, the App and website pages (or any part of them), the Facilities and elements of the Facilities may not be copied, reverse engineered, reproduced, modified, published, disseminated, transmitted, used, licensed, represented, exploited for commercial gain or distributed in any form whatsoever, in whole or in part, without our express written permission."
          )
        ]),
        _c("p", [
          _vm._v(
            "7.2.\tAny rights of use of any Sharo IPR that may be granted by us to you are strictly limited to accessing, downloading, printing for the sole purpose of proper ordinary use of the App, website and Facilities for journey sharing using the Facilities, and to use of these documents for private and personal purposes only within the scope of and for the duration of the Member’s membership of the Sharo App. Any other use by the Member is strictly prohibited."
          )
        ]),
        _c("p", [_vm._v("7.3.\tMember Content and Data ")]),
        _c("p", [
          _vm._v(
            "You hereby grant (and represent and warrant that you have the right to grant) to us a royalty-free, non-exclusive, licence (together with the right to grant sub-licences) to use, copy, reproduce, represent, adapt, modify, translate, scan, sub-licence, assign, transmit, and create derivative works to any and all content, information, data, images and materials you publish, upload, post, disseminate, broadcast or distribute on or through the App or the Facilities, solely to the extent necessary to perform the services of the App (“Mamber Content”). This licence shall continue for the duration of your membership to the App and for a period of 24 months thereafter in accordance with the retention period in our Privacy Policy , Any non-Personal Data that is collected through any Member registration process or otherwise shall be owned by Sharo."
          )
        ]),
        _c("p", [
          _vm._v(
            "7.4.\tYou hereby expressly authorise us to modify Member Content in order to conform to the look and feel of the App and the Facilities and communications media used by us or to make Member Content compatible with any technical processes or appropriate media formats."
          )
        ]),
        _c("p", [
          _vm._v(
            "7.5.\tThe rights referred to in condition 7.3 and/or condition 7.4 are worldwide and exist for the entirety of the agreement between us as described in these Terms."
          )
        ]),
        _c("p", [
          _vm._v(
            "7.6.\tYou must not copy, reproduce or otherwise use for any purpose whatsoever any content relating to other Members except to the extent required for the purpose for which Membership of the App is expressly permitted as described in these Terms."
          )
        ]),
        _c("h2", [_vm._v("8. Uploading content to the App")]),
        _c("p", [
          _vm._v(
            "8.1.\tWhenever you make use of the App, upload any content, information, data, images or materials to the App or make contact with other Members, you undertake to and warrant that you have complied in all respects with the Rules of Behaviour set out in condition 4 of these Terms, and you hereby indemnify us for any breach of that warranty."
          )
        ]),
        _c("p", [
          _vm._v(
            "8.2.\tAny content, information, data, images and materials you upload to the App or the Facilities is agreed by you to be non-confidential and non-proprietary and, without prejudice to condition 7.3, we have the right to use, copy, distribute and disclose to any other person any such content, information, data, images and materials for any purpose. We also have the right to disclose your identity to any person claiming that any content, information, data, images or materials published, uploaded, posted, disseminated, broadcast or distributed on or through the App or the Facilities constitutes a violation of their intellectual property rights, their right to privacy or any law."
          )
        ]),
        _c("p", [
          _vm._v(
            "8.3.\tWe shall be entitled to remove any content, information, data, images and materials you upload to and posting made on the App or the Facilities if, in our opinion, such material does not comply with the Rules of Behaviour set out in these Terms."
          )
        ]),
        _c("h2", [_vm._v("9. Limitation of our liability pto you")]),
        _c("p", [
          _vm._v(
            "9.1.\tYou agree that we and our Client Organisations shall have no responsibility for any personal or financial consequences, which result from the use of the Sharo App and Facilities. We are in no way responsible for the services and their fitness for purpose as provided by other Members of the App or other Service Providers including any responsibility for cancellation of agreements between Members or with/by Service Providers."
          )
        ]),
        _c("p", [
          _vm._v(
            "9.2.\tThe material displayed on and your access to use of the App and Facilities is provided without any guarantees, conditions or warranties as to its accuracy or completeness or suitability. To the fullest extent permitted by law (subject to condition 9.9), we and any and all third parties connected to us (including our employees, officers, agents, representatives, subcontractors and Client Organisations) hereby expressly exclude:"
          )
        ]),
        _c("p", [
          _vm._v(
            "9.2.1.\tany and all conditions, warranties and other terms (including any and all express or implied warranties) which might otherwise be implied by statute, common law or the law of equity;"
          )
        ]),
        _c("p", [
          _vm._v(
            "9.2.2.\tany and all liability for any direct, indirect and consequential loss, damage and costs incurred by you in connection with the use, inability to use, or arising (directly or indirectly) from use of the App or Facilities, any websites linked to either of them and any content, information, data, images and materials published, uploaded, posted on, disseminated, broadcast or distributed on or through the App or the Facilities, and any communications through the App and Facilities including loss of income or revenue, loss of business, loss of profits or contracts, loss of anticipated savings, loss or corruption of data (excluding Personal Data), loss of goodwill, and wasted management or office time, whether caused by tort (including our negligence), breach of contract, breach of statutory duty or otherwise, even if any such loss or damage is reasonably foreseeable."
          )
        ]),
        _c("p", [
          _vm._v(
            "9.3.\tYou acknowledge and agree that neither we nor any of our employees, officers, agents, representatives, subcontractors and Client Organisations endorse the accuracy or reliability of any advice, opinion, statement or other information displayed, uploaded or distributed or made available through use of the App or any of the Facilities, or provided by us or by any of our partner organisations, by any Member or by any other person or entity. We shall not be responsible or liable for the content of such materials or the conduct of Members (whether offline or online) or by any other person or entity. You are strongly advised to use caution and common sense when using the App or the Facilities and in meeting people following contact made through the App or the Facilities."
          )
        ]),
        _c("p", [
          _vm._v(
            "9.4.\tYou acknowledge that we and our employees, officers, agents, representatives, subcontractors and Client Organisations do not pre-screen content, but that we (and those authorised by us) have the right (but not the obligation) in our sole discretion to refuse, edit, move and remove any content that is made available on or through the App and/or the Facilities."
          )
        ]),
        _c("p", [
          _vm._v(
            "9.5.\tWe do not control content posted on or disseminated through the App or the Facilities and, as such, do not guarantee the accuracy, integrity or quality of any such content. You understand that by using the App or the Facilities, you may be exposed to content that is offensive, indecent or objectionable. Under no circumstances will we or our Client Organisations be liable in any way for any content including for any errors or omissions in any content or for any loss or damage of any kind incurred as a result of the use of any content posted, emailed or otherwise disseminated or transmitted through the App or the Facilities or resulting from use of the App or Facilities."
          )
        ]),
        _c("p", [
          _vm._v(
            "9.6.\tIf you order goods, services or facilities from anyone other than Sharo whether through any commercial on Sharo website or services accessible through the App or the Facilities, all transaction terms including but not limited to purchase terms, payment terms, warranties, guarantees, maintenance, and delivery, are solely between you and the seller of the goods or facilities in question. You are solely responsible for fulfilling any contractual, e-commerce or other obligations you assume using the App or the Facilities. We give no warranties or representations whatsoever with regard to any goods or facilities provided by the seller of the goods or facilities and shall not be liable for any costs, damages or costs arising (directly or indirectly) from the goods or services or facilities ordered or from any actions or inactions of the seller of any of those items."
          )
        ]),
        _c("p", [
          _vm._v(
            "9.7.\tWe shall not be liable for any delay or failure to perform resulting directly or indirectly from any causes beyond our reasonable control."
          )
        ]),
        _c("p", [
          _vm._v(
            "9.8.\tWe shall not be liable for any payments made from passenger to driver. Please contact your driver regarding cost contributions made in error."
          )
        ]),
        _c("p", [
          _vm._v(
            "9.9.\tNothing in these Terms shall exclude or limit our liability for (a) death or personal injury caused by negligence; (b) fraudulent misrepresentation or misrepresentation as to a fundamental matter; or (c) any other liability which cannot be excluded or limited under applicable law."
          )
        ]),
        _c("h2", [_vm._v("10. Your indemnity to us")]),
        _c("p", [
          _vm._v(
            "10.1.\tYou agree to indemnify us, our holding companies, our subsidiaries, our officers employees, agents, representatives, subcontractors and Client Organisations against any and all claims, demands, costs, losses, liabilities and expenses including legal expenses and consequential loss (on an indemnity basis) arising out of or relating to:"
          )
        ]),
        _c("p", [
          _vm._v("10.1.1.\tyour use of the App and/or the Facilities;")
        ]),
        _c("p", [_vm._v("10.1.2.\tany breach of these Terms; ")]),
        _c("p", [
          _vm._v(
            "10.1.3.\tthe Member Content infringing a third party’s Intellectual Property Rights;"
          )
        ]),
        _c("p", [
          _vm._v(
            "10.1.4.\tthe Member Content’s contents, accuracy or completeness; "
          )
        ]),
        _c("p", [
          _vm._v(
            "10.1.5.\tany defamatory, offensive or illegal content, information or materials provided by you either directly or indirectly to us. and/or"
          )
        ]),
        _c("p", [
          _vm._v(
            "10.1.6.\tbreach of any warranty or representation given by or on behalf of you."
          )
        ]),
        _c("h2", [_vm._v("11.\tLinks to other websites or apps")]),
        _c("p", [
          _vm._v(
            "11.1.\tPlease note that links to websites or apps created and/or maintained by organisations other than ourselves may be available on or through the App and/or the Facilities. However, we cannot accept any liability for the accuracy or content of such other websites and we do not necessarily endorse the views expressed within any of them. We will not be held responsible or liable, directly or indirectly, for any damage or loss caused or alleged to be caused by or in connection with use of or reliance on any such content, goods or services on or through any such website or service. We cannot guarantee that these links will operate all of the time and we have no control over the availability of any linked pages."
          )
        ]),
        _c("h2", [_vm._v("12.Termination")]),
        _c("p", [
          _vm._v(
            "12.1.\tWe may close your account on the App and/or the Facilities at any time without cause, or if we believe, in our sole discretion, that you have violated any of the Rules of Behaviour set out in condition 4 of these Terms. We may also immediately terminate your Membership and right to access and use the App and the Facilities if:"
          )
        ]),
        _c("p", [
          _vm._v(
            "12.1.1.\tyou breach the agreement made between us as set out in these Terms;"
          )
        ]),
        _c("p", [
          _vm._v(
            "12.1.2.\twe are unable to verify or authenticate (should we choose to do so without being obliged to do so) any information you provide to us;"
          )
        ]),
        _c("p", [
          _vm._v(
            "12.1.3.\tany information which you provide to us is inaccurate;"
          )
        ]),
        _c("p", [
          _vm._v(
            "12.1.4.\twe decide, in our sole discretion, that you have abandoned your account; "
          )
        ]),
        _c("p", [
          _vm._v(
            "12.1.5.\twe decide, in our sole discretion, to discontinue operating, maintaining and/or offering all or any of the App and/or the Facilities; and/or"
          )
        ]),
        _c("p", [
          _vm._v(
            "12.1.6.\tyou act in a manner which we decide, in our sole discretion, to be fraudulent and/or contravene any applicable anti money laundering legislation."
          )
        ]),
        _c("p", [
          _vm._v(
            "12.2.\tWe shall not be liable to you or any third party for termination of the App or all or any of the Facilities."
          )
        ]),
        _c("p", [
          _vm._v(
            "12.3.\tTermination of your Membership and any closure of your account by us shall be without prejudice to any other rights or remedies that we may have including any and all claims under any indemnity set out in these Terms or any and all claims for damages in respect of any loss suffered as a result of breach of any of these Terms by you."
          )
        ]),
        _c("p", [
          _vm._v(
            "12.4.\tYou may at any time and with or without cause terminate your registration with the App and the Facilities and your Membership by cancelling your account and notifying us of that cancellation. In cancelling your account, you agree to delete yourself from the App and the Facilities through the ‘My Account’ page."
          )
        ]),
        _c("p", [
          _vm._v(
            "12.5.\tIn the event of termination of your Membership you will remain obligated not to violate the rights or privacy of any person or entity with respect to any materials you may have received or downloaded through and/or from the App and/or the Facilities."
          )
        ]),
        _c("h2", [
          _vm._v("13.\tMatters outside of our control (force majeure)")
        ]),
        _c("p", [
          _vm._v(
            "13.1.\tNeither party shall be liable for any failure to perform its obligations under these Terms if such failure results from circumstances which could not reasonably be contemplated at the time of entering into these Terms and which are beyond the parties’ reasonable control (including, without limitation, strikes, lock-outs or other industrial disputes (involving the workforce of Sharo), failure of a utility service or transport network, war, riot, civil commotion, terrorism, malicious damage, compliance with any law or governmental order, rule, regulation or direction, accident, fire, flood, storm or default of suppliers or sub-contractors). If a force majeure event continues for a period of 30 days or more, either party may terminate these Terms immediately by providing the other party with written notice. "
          )
        ]),
        _c("h2", [_vm._v("14. Variations to terms")]),
        _c("p", [
          _vm._v(
            "14.1.\tThe information, material and content provided in the pages of the App and the Facilities may be changed at any time and from time to time without notice. We may revise these Terms at any time without prior notice. You agree that you will check this page on the App on a regular basis to take notice of any changes we make, as they are binding on you. Some of the provisions contained in these Terms may also be superseded by provisions or notices published elsewhere on our website."
          )
        ]),
        _c("h2", [_vm._v("15. Entire agreement and miscellaneous provisions")]),
        _c("p", [
          _vm._v(
            "15.1.\tThese Terms, and the pages on the App and the Facilities to which these Terms refer, constitute the entire agreement between us. They cancel and supersede all prior understandings, proposals, agreements, negotiations, and discussions between us whether written or oral."
          )
        ]),
        _c("p", [
          _vm._v(
            "15.2.\tEach party agrees that it shall have no remedies in respect of any statement, representation, assurance or warranty (whether made innocently or negligently) that is not set out in these Terms. Each party agrees that it shall have no claim for innocent or negligent misrepresentation or negligent misstatement based on any statement in these Terms. "
          )
        ]),
        _c("p", [
          _vm._v(
            "15.3.\tIf any provision (or part of any provision) of these Terms is held to be invalid or unenforceable, such provision shall be struck and the remaining provisions will continue in force without being impaired or invalidated in anyway."
          )
        ]),
        _c("p", [
          _vm._v(
            "15.4.\tNo forbearance, delay or indulgence by either of us in enforcing our respective rights shall prejudice or restrict those rights. Neither of us shall be deemed to have waived any of our respective rights under these Terms because of our respective failure or delay in exercising that right."
          )
        ]),
        _c("p", [
          _vm._v(
            "15.5.\tYou may not assign, transfer and/or subcontract all or any of your rights and/or obligations under the agreement between us set out in these Terms which are personal to you and any attempt on your part to the contrary is void. The agreement made between us as evidenced in these Terms shall inure to the benefit of and be binding upon each of our successors and assigns."
          )
        ]),
        _c("p", [
          _vm._v(
            "15.6.\tNothing in these Terms is intended to or shall operate to create a partnership between the parties, or to authorise either party to act as agent for the other, and neither party shall have authority to act in the name or on behalf of or otherwise to bind the other in any way (including but not limited to the making of any representation or warranty, the assumption of any obligation or liability and the exercise of any right or power)."
          )
        ]),
        _c("p", [
          _vm._v(
            "15.7.\tAny notice required or permitted to be given to you by us under these Terms shall be delivered by electronic mail to the email address provided by you during registration on the App."
          )
        ]),
        _c("h2", [_vm._v("16. Rights of third parties")]),
        _c("p", [
          _vm._v(
            "16.1.\tA person who is not a party to the agreement between us has no right to rely upon or enforce any provision of these Terms."
          )
        ]),
        _c("h2", [_vm._v("17. Jurisdiction and governing law")]),
        _c("p", [
          _vm._v(
            "17.1.\tThese Terms and the agreement made between us are governed by and will be interpreted in accordance with the laws of Ireland. The courts of Ireland will have exclusive jurisdiction in respect of any claim or dispute which may arise in connection with these Terms or in connection with or arising from any use of the App or the Facilities."
          )
        ]),
        _c("h2", [_vm._v("18. Concerns")]),
        _c("p", [
          _vm._v(
            "18.1.\tIf you have any concerns about any material whatsoever which appears on the App or the Facilities, please contact hello@sharo.io."
          )
        ]),
        _c("p", [
          _vm._v(
            "18.2.\tIf you believe that any communication made through or on the App and/or any of the Facilities infringes any legal rights that you may have or is in breach of any of these Terms (including the Rules of Behaviour) or know of or suspect any unauthorised posting of or attempt to post any communications, you should notify us immediately with specific details by email at the following address: hello@sharo.io."
          )
        ]),
        _c("p", [
          _vm._v(
            "18.3.\tYou agree to comply with all reasonable requests from us, the police, or any statutory or regulatory authorities in identifying any unauthorised users of the App and/or the Facilities."
          )
        ]),
        _c("h2", [_vm._v("19. Our details")]),
        _c("p", [_vm._v("Sharo Mobility")]),
        _c("p", [_vm._v("4/5 Burton Hall Park, ")]),
        _c("p", [_vm._v("Sandyford Industrial Estate, ")]),
        _c("p", [_vm._v("Dublin D18 A094")]),
        _c("p", [_vm._v("Email: hello@sharo.io")])
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }